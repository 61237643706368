export default [
    // LEADS INDEX
    {
        path: '/conveyancing/residential/purchases/leads',
        name: 'residential.purchase.leads',
        component: () => import('@/views/dashboard/LeadsIndex.vue'),
        meta: {
            service: 'res-purchase',
            type: 'lead',
            smallMenu: true,
            active: 'residential'
        },
    // LEADS SINGLE
    },
        {
            path: '/conveyancing/residential/purchases/leads/view/:reference?',
            name: 'services.res-purchase.leads.view',
            component: () => import('@/views/dashboard/LeadSingle.vue'),
            meta: {
                service: 'res-purchase',
                type: 'lead',
                smallMenu: true,
                active: 'residential',
            }
        },

    // QUOTES INDEX
    {
        path: '/conveyancing/residential/purchases/quotes',
        name: 'residential.purchase.quotes',
        component: () => import('@/views/dashboard/residential/ResidentialQuotesIndex.vue'),
        meta: {
            service: 'res-purchase',
            title: 'Residential Purchase: Quotes',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
    },
    // QUOTES SINGLE
    {
        path: '/conveyancing/residential/purchases/quotes/view/:reference?',
        component: () => import('@/views/layouts/SingleQuoteWrapper.vue'),
        props: true,
        meta: {
            service: 'res-purchase',
            type: 'quote',
            smallMenu: true,
            active: 'residential'
        },
        children: [
            {
                path: 'view',
                name: 'services.res-purchase.quotes.view',
                component: () => import('@/components/services/QuoteMatterDetailsTab.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'services.res-purchase.quotes.view.fees',
                component: () => import('@/components/dashboard/misc/QuoteMatterFeesTab.vue'),
                props: true,
            },
            {
                path: 'documents',
                name: 'services.res-purchase.quotes.view.documents',
                component: () => import('@/components/dashboard/misc/QuoteMatterDocuments.vue'),
                props: true,
            },
            {
                path: 'submission',
                name: 'services.res-purchase.quotes.view.submission',
                component: () => import('@/components/dashboard/misc/QuoteMatterOriginalSubmission.vue'),
                props: true,
            },
            {
                path: 'activity',
                name: 'services.res-purchase.quotes.view.activity',
                component: () => import('@/components/dashboard/misc/QuoteMatterActivityTab.vue'),
                props: true,
            },
        ]
    },
    
    // MATTERS INDEX
    {
        path: '/conveyancing/residential/purchases/matters',
        name: 'residential.purchase.matters',
        component: () => import('@/views/dashboard/residential/ResidentialPurchaseMatters.vue'),
        meta: {
            service: 'res-purchase',
            type: 'matter',
            smallMenu: true,
            active: 'residential'
        },
    },
]