export default [
    // res-sale
    {
        path: '/settings/services/res-sale',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'res-sale',
            smallMenu: true,
            active: 'settings',
        },
        children: [
            {
                path: 'general',
                name: 'settings.service.res-sale.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
            },
            {
                path: 'form',
                name: 'settings.service.res-sale.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
            },
            {
                path: 'fees',
                name: 'settings.service.res-sale.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                children:[
                    {
                        path: '',
                        name: 'settings.service.res-sale.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.res-sale.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.res-sale.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.res-sale.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'tasks',
                name: 'settings.service.res-sale.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
            },
            {
                path: 'content',
                name: 'settings.service.res-sale.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.res-sale.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // res-purchase
    {
        path: '/settings/services/res-purchase',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'res-purchase',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'general',
                name: 'settings.service.res-purchase.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'form',
                name: 'settings.service.res-purchase.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'fees',
                name: 'settings.service.res-purchase.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.res-purchase.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.res-purchase.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.res-purchase.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.res-purchase.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                    {
                        path: 'sdlt',
                        name: 'settings.service.res-purchase.fees.sdlt',
                        component: () => import('@/components/settings/fees/FeeSettingsSDLT.vue'),
                        props: true,
                        meta:{
                            feeType:'sdlt',
                            feeName: 'SDLT'
                        }
                    },
                    {
                        path: 'hmlr',
                        name: 'settings.service.res-purchase.fees.hmlr',
                        component: () => import('@/components/settings/fees/FeeSettingsHMLR.vue'),
                        props: true,
                        meta:{
                            feeType:'hmlr',
                            feeName: 'HMLR'
                        }
                    }
                ]
            },
            {
                path: 'tasks',
                name: 'settings.service.res-purchase.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.res-purchase.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.res-purchase.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // res-remortgage
    {
        path: '/settings/services/res-remortgage',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'res-remortgage',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.res-remortgage.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.res-remortgage.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.res-remortgage.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.res-remortgage.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.res-remortgage.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.res-remortgage.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.res-remortgage.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                    {
                        path: 'hmlr',
                        name: 'settings.service.res-remortgage.fees.hmlr',
                        component: () => import('@/components/settings/fees/FeeSettingsHMLR.vue'),
                        props: true,
                        meta:{
                            feeType:'hmlr',
                            feeName: 'HMLR'
                        }
                    }
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.res-remortgage.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.res-remortgage.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.res-remortgage.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // res-transfer
    {
        path: '/settings/services/res-transfer',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'res-transfer',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.res-transfer.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.res-transfer.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.res-transfer.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.res-transfer.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.res-transfer.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.res-transfer.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.res-transfer.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.res-transfer.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.res-transfer.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.res-transfer.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // commercial
    {
        path: '/settings/services/commercial',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'commercial',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.commercial.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.commercial.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.commercial.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.commercial.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.commercial.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.commercial.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.commercial.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.commercial.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.commercial.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.commercial.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // will
    {
        path: '/settings/services/will',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'will',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.will.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.will.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.will.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.will.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.will.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.will.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.will.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.will.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.will.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.will.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // lpa
    {
        path: '/settings/services/lpa',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'lpa',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.lpa.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.lpa.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.lpa.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.lpa.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.lpa.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.lpa.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.lpa.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.lpa.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.lpa.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.lpa.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // probate
    {
        path: '/settings/services/probate',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'probate',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.probate.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.probate.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.probate.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.probate.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.probate.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.probate.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.probate.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.probate.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.probate.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.probate.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // employment
    {
        path: '/settings/services/employment',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'employment',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.employment.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.employment.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.employment.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.employment.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.employment.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.employment.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.employment.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.employment.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.employment.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.employment.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // dispute
    {
        path: '/settings/services/dispute',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'dispute',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.dispute.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.dispute.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.dispute.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.dispute.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.dispute.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.dispute.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.dispute.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.dispute.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.dispute.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.dispute.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // family
    {
        path: '/settings/services/family',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'family',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.family.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.family.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.family.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.family.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.family.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.family.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.family.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.family.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.family.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.family.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // commercial and corporate
    {
        path: '/settings/services/commercial-corporate',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'commercial-corporate',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.commercial-corporate.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.commercial-corporate.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.commercial-corporate.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.commercial-corporate.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.commercial-corporate.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.commercial-corporate.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.commercial-corporate.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.commercial-corporate.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.commercial-corporate.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.commercial-corporate.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
    // court of protection
    {
        path: '/settings/services/court',
        component: () => import('@/views/dashboard/settings/SettingsService.vue'),
        meta: {
            service: 'court',
            smallMenu: true,
            active: 'settings',
        },
        props: true,
        children: [
            {
                path: 'view',
                name: 'settings.service.court.general',
                component: () => import('@/components/settings/conveyancing/SettingsGeneral.vue'),
                props: true,
            },
            {
                path: 'view/form',
                name: 'settings.service.court.form',
                component: () => import('@/components/settings/forms/SettingsFormEditor.vue'),
                props: true,
            },
            {
                path: 'view/fees',
                name: 'settings.service.court.fees',
                component: () => import('@/components/settings/fees/SettingsFeeEditor.vue'),
                props: true,
                children:[
                    {
                        path: '',
                        name: 'settings.service.court.fees.ranges',
                        component: () => import('@/components/settings/fees/FeeSettingsRanges.vue'),
                        props: true,
                    },
                    {
                        path: 'dynamic',
                        name: 'settings.service.court.fees.dynamic',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'dynamic',
                            feeName: 'Dynamic'
                        }
                    },
                    {
                        path: 'additional',
                        name: 'settings.service.court.fees.additional',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'additional',
                            feeName: 'Additional'
                        }
                    },
                    {
                        path: 'disbursement',
                        name: 'settings.service.court.fees.disbursements',
                        component: () => import('@/components/settings/fees/FeeSettingsView.vue'),
                        props: true,
                        meta:{
                            feeType:'disbursement',
                            feeName: 'Disbursement'
                        }
                    },
                ]
            },
            {
                path: 'view/tasks',
                name: 'settings.service.court.tasks',
                component: () => import('@/components/settings/tasks/SettingsTaskMapping.vue'),
                props: true,
            },
            {
                path: 'content',
                name: 'settings.service.court.content',
                component: () => import('@/components/settings/SettingsContentEditor.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'settings.service.court.terms-and-conditions',
                component: () => import('@/components/settings/SettingsTermsConditions.vue'),
            }
        ],
    },
]