<template>
    <div class="flex flex-col w-48 p-2">
        <div v-if="header" class="submenu-header p-2 font-bold">
            {{header}}
        </div>
        <router-link 
            v-for="(link) in links" 
            :key="link" 
            :to="{name: link.t, params:{service: link.p}, meta:{heading: link.n}}"
            class="block p-2 hover:bg-gray-100 transition-colors"
            :class="link.c"
        >{{link.n}}</router-link>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex';

export default defineComponent({
    name: 'SubMenu',
    props: {
        links: {
            type: Array,
            default: () => []
        },
        header: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const store = useStore();
        const authRole = computed(() => store.getters.authRole);

        const filteredLinks = computed(() => {
            return props.links.filter((link: any) => {
                if(!link.perm) return true;
                return authRole.value(link.perm);
            })
        })

        return {
            authRole,
            filteredLinks,
        }
    },
})
</script>

<style scoped>
.router-link-active:not(.home-link),
.router-link-active {
    font-weight: bold;
}

.submenu-header {
    color: #922643;
}
</style>