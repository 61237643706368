import { useRoute } from "vue-router";

export default function() {
    const route = useRoute();

    const otherServicesTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.other.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.other.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.other.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.other.quote.view.activity'
        },
    ];

    const familyTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.family.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.family.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.family.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.family.quote.view.activity'
        },
    ];

    const lpaTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.lpa.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.lpa.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.lpa.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.lpa.quote.view.activity'
        },
    ];

    const probateTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.probate.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.probate.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.probate.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.probate.quote.view.activity'
        },
    ];

    const employmentTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.employment.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.employment.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.employment.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.employment.quote.view.activity'
        },
    ];

    const employmentSettlementTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.employment-settlement.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.employment-settlement.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.employment-settlement.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.employment-settlement.quote.view.activity'
        },
    ];

    const disputesTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.disputes.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.disputes.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.disputes.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.disputes.quote.view.activity'
        },
    ];

    const courtTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.court.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.court.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.court.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.court.quote.view.activity'
        },
    ];

    const commercialPropertyTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.commercial.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.commercial.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.commercial.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.commercial.quote.view.activity'
        },
    ];

    const commercialCorporateTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.commercial-corporate.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.commercial-corporate.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.commercial-corporate.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.commercial-corporate.quote.view.activity'
        },
    ];

    const willTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.wills.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.wills.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.wills.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.wills.quote.view.activity'
        },
    ];

    const residentialPurchaseTabMenuLinks = [
        {
            label: 'Purchase Details',
            route: 'services.res-purchase.quotes.view'
        },
        {
            label: 'Fees',
            route: 'services.res-purchase.quotes.view.fees'
        },
        {
            label: 'Documents',
            route: 'services.res-purchase.quotes.view.documents'
        },
        {
            label: 'Original Submission',
            route: 'services.res-purchase.quotes.view.submission'
        },
        {
            label: 'Activity',
            route: 'services.res-purchase.quotes.view.activity'
        }
    ];

    const residentialRemortgageTabMenuLinks = [
        {
            label: 'Remortgage Details',
            route: 'services.res-remortgage.quotes.view'
        },
        {
            label: 'Fees',
            route: 'services.res-remortgage.quotes.view.fees'
        },
        {
            label: 'Documents',
            route: 'services.res-remortgage.quotes.view.documents'
        },
        {
            label: 'Original Submission',
            route: 'services.res-remortgage.quotes.view.submission'
        },
        {
            label: 'Activity',
            route: 'services.res-remortgage.quotes.view.activity'
        }
    ];

    const  residentialSaleSidebarLinks = [
        // NAME, TO, CLASS
        {
            n: 'Matters',
            t: 'residential.sales.matters',
            c: 'home-link'
        },
        {
            n: 'Quotes',
            t: 'residential.sales.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.sales.leads',
            c: 'home-link'
        },
    ];

    const  residentialPurchaseSidebarLinks = [
        // NAME, TO, CLASS
        {
            n: 'Matters',
            t: 'residential.purchase.matters',
            c: 'home-link'
        },
        {
            n: 'Quotes',
            t: 'residential.purchase.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.purchase.leads',
            c: 'home-link'
        },
    ];

    const  residentialRemortgageSidebarLinks = [
         // NAME, TO, CLASS
         {
            n: 'Matters',
            t: 'residential.remortgages.matters',
            c: 'home-link'
        },
        {
            n: 'Quotes',
            t: 'residential.remortgages.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.remortgages.leads',
            c: 'home-link'
        },
    ];

    const  residentialTransferSidebarLinks = [
        // NAME, TO, CLASS
        {
            n: 'Matters',
            t: 'residential.transfers.matters',
            c: 'home-link'
        },
        {
            n: 'Quotes',
            t: 'residential.transfers.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.transfers.leads',
            c: 'home-link'
        },
    ];

    const  residentialEquitySidebarLinks = [
        // NAME, TO, CLASS
        // {
        //     n: 'Matters',
        //     t: 'residential.equity.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'residential.equity.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.equity.leads',
            c: 'home-link'
        },
    ];

    const  residentialSalePurchaseSidebarLinks = [
        // NAME, TO, CLASS
        // {
        //     n: 'Matters',
        //     t: 'residential.res-sale-purchase.matters',
        //     c: 'home-link'
        // },
        // {
        //     n: 'Quotes',
        //     t: 'services.res-sale-purchase.quotes',
        //     c: 'home-link'
        // },
        {
            n: 'Leads',
            t: 'services.res-sale-purchase.leads',
            c: 'home-link'
        },
    ];

    const  residentialLegalAdviceSidebarLinks = [
        // NAME, TO, CLASS
        // {
        //     n: 'Matters',
        //     t: 'residential.legal-advice.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'residential.legal-advice.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.legal-advice.leads',
            c: 'home-link'
        },
    ];

    const  residentialLeaseSidebarLinks = [
        // NAME, TO, CLASS
        // {
        //     n: 'Matters',
        //     t: 'residential.lease.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'residential.lease.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.lease.leads',
            c: 'home-link'
        },
    ];

    const  residentialHtbSidebarLinks = [
        // NAME, TO, CLASS
        // {
        //     n: 'Matters',
        //     t: 'residential.htb.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'residential.htb.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.htb.leads',
            c: 'home-link'
        },
    ];

    const  residentialGeneralSidebarLinks = [
        // NAME, TO, CLASS
        // {
        //     n: 'Matters',
        //     t: 'residential.general.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'residential.general.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'residential.general.leads',
            c: 'home-link'
        },
    ];

    const residentialSaleTabMenuLinks = [
        {
            label: 'Sale Details',
            route: 'services.res-sale.quotes.view'
        },
        {
            label: 'Fees',
            route: 'services.res-sale.quotes.view.fees'
        },
        {
            label: 'Documents',
            route: 'services.res-sale.quotes.view.documents'
        },
        {
            label: 'Original Submission',
            route: 'services.res-sale.quotes.view.submission'
        },
        {
            label: 'Activity',
            route: 'services.res-sale.quotes.view.activity'
        }
    ];

    const residentialSaleMatterTabMenuLinks = [
        {
            label: 'Sale Details',
            route: 'residential.sales.matters.view'
        },
        {
            label: 'Tasks',
            route: 'residential.sales.matters.view.tasks'
        },
        {
            label: 'Fees',
            route: 'residential.sales.matters.view.fees'
        },
        {
            label: 'Documents',
            route: 'residential.sales.matters.view.documents'
        },
        {
            label: 'Original Submission',
            route: 'residential.sales.matters.view.submission'
        },
        {
            label: 'Activity',
            route: 'residential.sales.matters.view.activity'
        }
    ];
    
    const residentialTransferTabMenuLinks = [
        {
            label: 'Transfer Details',
            route: 'services.res-transfer.quotes.view'
        },
        {
            label: 'Fees',
            route: 'services.res-transfer.quotes.view.fees'
        },
        {
            label: 'Documents',
            route: 'services.res-transfer.quotes.view.documents'
        },
        {
            label: 'Original Submission',
            route: 'services.res-transfer.quotes.view.submission'
        },
        {
            label: 'Activity',
            route: 'services.res-transfer.quotes.view.activity'
        }
    ];

    const residentialSalePurchaseTabMenuLinks = [
        {
            label: 'Details',
            route: 'services.res-sale-purchase.quote.view'
        },
        {
            label: 'Summary',
            route: 'services.res-sale-purchase.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'services.res-sale-purchase.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'services.res-sale-purchase.quote.view.activity'
        },
    ];

    const residentialEquityTabMenuLinks = [
        {
            label: 'Details',
            route: 'residential.equity.quote.view'
        },
        {
            label: 'Summary',
            route: 'residential.equity.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'residential.equity.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'residential.equity.quote.view.activity'
        },
    ];

    const residentialLegalAdviceTabMenuLinks = [
        {
            label: 'Details',
            route: 'residential.legal-advice.quote.view'
        },
        {
            label: 'Summary',
            route: 'residential.legal-advice.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'residential.legal-advice.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'residential.legal-advice.quote.view.activity'
        },
    ];

    const residentialLeaseTabMenuLinks = [
        {
            label: 'Details',
            route: 'residential.lease.quote.view'
        },
        {
            label: 'Summary',
            route: 'residential.lease.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'residential.lease.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'residential.lease.quote.view.activity'
        },
    ];


    const residentialGeneralTabMenuLinks = [
        {
            label: 'Details',
            route: 'residential.general.quote.view'
        },
        {
            label: 'Summary',
            route: 'residential.general.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'residential.general.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'residential.general.quote.view.activity'
        },
    ];


    const residentialHtbTabMenuLinks = [
        {
            label: 'Details',
            route: 'residential.htb.quote.view'
        },
        {
            label: 'Summary',
            route: 'residential.htb.quote.view.summary'
        },
        {
            label: 'Fees',
            route: 'residential.htb.quote.view.fees'
        },
        {
            label: 'Activity',
            route: 'residential.htb.quote.view.activity'
        },
    ];


    const settingsGlobalTabMenuLinks = [
        {
            label: 'General',
            route: 'settings'
        },
        {
            label: 'Terms & Conditions',
            route: 'settings.terms'
        },
        {
            label: 'Privacy Policy',
            route: 'settings.privacy'
        },
        {
            label: 'Data Export',
            route: 'settings.data-export'
        }
    ]

    const settingsServiceTabMenuLinks = [
        {
            label: 'General',
            route: 'settings.service.'+route.meta.service+'.general'
        },
        {
            label: 'Form',
            route: 'settings.service.'+route.meta.service+'.form'
        },
        {
            label: 'Fees',
            route: 'settings.service.'+route.meta.service+'.fees.ranges'
        },
        {
            label: 'Tasks',
            route: 'settings.service.'+route.meta.service+'.tasks'
        },
        {
            label: 'Content',
            route: 'settings.service.'+route.meta.service+'.content'
        },
        {
            label: 'Terms & Conditions',
            route: 'settings.service.'+route.meta.service+'.terms-and-conditions'
        },
    ]

    const feesSubTabMenuLinks = [
        {
            label: 'Ranges',
            route: 'settings.service.'+route.meta.service+'.fees.ranges'
        },
        {
            label: 'Dynamic Fees',
            route: 'settings.service.'+route.meta.service+'.fees.dynamic'
        },
        {
            label: 'Additional Fees',
            route: 'settings.service.'+route.meta.service+'.fees.additional'
        },
        {
            label: 'Disbursements',
            route: 'settings.service.'+route.meta.service+'.fees.disbursements'
        }
    ]

    const usersEmployeeTabMenuLinks = [
        {
            label: 'Details',
            route: 'users.employees.edit'
        },
        {
            label: 'Permissions',
            route: 'users.employees.edit.permissions'
        },
        {
            label: 'Activity',
            route: 'users.employees.edit.activity'
        },
    ]

    const usersSidebarLinks = [
        {
            n: 'Overview',
            t: 'users',
            c: 'home-link',
            perm: 'view-users'
        },
        {
            n: 'Clients',
            t: 'users.clients',
            c: 'home-link',
            perm: 'view-users'
        },
        {
            n: 'Introducers',
            t: 'users.introducers',
            c: 'home-link',
            perm: 'view-introducers',
        },
        {
            n: 'Kew Law',
            t: 'users.employees',
            c: 'home-link',
            perm: 'view-employees',
        },
        {
            n: 'Admin',
            t: 'users.admin',
            c: 'home-link',
            perm: 'view-admin',
        },
    ]
    
    const branchesSidebarLinks = [
        {
            n: 'Overview',
            t: 'branches',
            c: 'home-link',
        },
        {
            n: 'Introducers',
            t: 'branches.introducers',
            c: 'home-link',
            perm: 'introducers',
        },
        {
            n: 'Kew Law',
            t: 'branches.kew-law',
            c: 'home-link',
            perm: 'branches',
        },
    ]

    const companiesSidebarLinks = [
        {
            n: 'Overview',
            t: 'companies',
            c: 'home-link',
        },
        {
            n: 'Introducers',
            t: 'companies.introducers',
            c: 'home-link',
            perm: 'introducers',
        },
    ]

    const willSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.wills',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.wills.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.wills.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.wills.leads',
            c: 'home-link'
        },
    ]

    const commercialCorporateSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.commercial-corporate',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.commercial-corporate.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.commercial-corporate.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.commercial-corporate.leads',
            c: 'home-link'
        },
    ]

    const courtSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.court',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.court.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.court.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.court.leads',
            c: 'home-link'
        },
    ]

    const commercialPropertySidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.commercial',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.commercial.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.commercial.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.commercial.leads',
            c: 'home-link'
        },
    ]

    const lpaSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.lpa',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.lpa.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.lpa.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.lpa.leads',
            c: 'home-link'
        },
    ]

    const probateSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.probate',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.probate.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.probate.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.probate.leads',
            c: 'home-link'
        },
    ]

    const otherServicesSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.other',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.other.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.other.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.other.leads',
            c: 'home-link'
        },
    ]

    const familySidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.family',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.family.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.family.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.family.leads',
            c: 'home-link'
        },
    ]

    const employmentSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.employment',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.employment.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.employment.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.employment.leads',
            c: 'home-link'
        },
    ]

    const employmentSettlementSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.employment-settlement',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.employment-settlement.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.employment-settlement.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.employment-settlement.leads',
            c: 'home-link'
        },
    ]

    const disputesSidebarLinks = [
        // NAME, TO, CLASS, PERMISSION
        {
            n: 'Overview',
            t: 'services.disputes',
            c: 'home-link'
        },
        // {
        //     n: 'Matters',
        //     t: 'services.disputes.matters',
        //     c: 'home-link'
        // },
        {
            n: 'Quotes',
            t: 'services.disputes.quotes',
            c: 'home-link'
        },
        {
            n: 'Leads',
            t: 'services.disputes.leads',
            c: 'home-link'
        },
    ]

    const settingsGeneralSidebarLinks = [
        // NAME, TO, CLASS
        {
            n: 'General',
            t: 'settings',
            c: 'home-link'
        },
        {
            n: 'Tasks',
            t: 'settings.tasks',
            c: 'child-link'
        },
    ]

    const settingsConveyancingSidebarLinks = [
        {
            n: 'Residential Puchase',
            t: 'settings.service.res-purchase.general',
            c: 'child-link'
        },
        {
            n: 'Residential Sale',
            t: 'settings.service.res-sale.general',
            c: 'child-link'
        },
        {
            n: 'Remortgage',
            t: 'settings.service.res-remortgage.general',
            c: 'child-link'
        },
        {
            n: 'Transfer',
            t: 'settings.service.res-transfer.general',
            c: 'child-link'
        }
    ]

    const settingsServicesSidebarLinks = [
        // NAME, TO, CLASS
        {
            n: 'Commercial',
            t: 'settings.service.commercial.general',
            c: 'child-link'
        },
        {
            n: 'LPA',
            t: 'settings.service.lpa.general',
            c: 'child-link'
        },
        {
            n: 'Probate services',
            t: 'settings.service.probate.general',
            c: 'child-link'
        },
        {
            n: 'Wills',
            t: 'settings.service.will.general',
            c: 'child-link'
        },
        {
            n: 'Family Law',
            t: 'settings.service.family.general',
            c: 'child-link'
        },
        {
            n: 'Employment Law',
            t: 'settings.service.employment.general',
            c: 'child-link'
        },
        {
            n: 'Commercial and Corporate',
            t: 'settings.service.commercial-corporate.general',
            c: 'child-link'
        },
        {
            n: 'Court of Protection',
            t: 'settings.service.court.general',
            c: 'child-link'
        },
        {
            n: 'Disputes & Litigation',
            t: 'settings.service.dispute.general',
            c: 'child-link'
        },
        
    ]

    return {
        willTabMenuLinks,
        willSidebarLinks,
        commercialCorporateSidebarLinks,
        commercialCorporateTabMenuLinks,
        courtSidebarLinks,
        courtTabMenuLinks,
        commercialPropertySidebarLinks,
        commercialPropertyTabMenuLinks,
        lpaSidebarLinks,
        lpaTabMenuLinks,
        probateSidebarLinks,
        probateTabMenuLinks,
        familySidebarLinks,
        familyTabMenuLinks,
        employmentSidebarLinks,
        employmentTabMenuLinks,
        employmentSettlementSidebarLinks,   
        employmentSettlementTabMenuLinks,
        disputesSidebarLinks,
        disputesTabMenuLinks,
        otherServicesSidebarLinks,
        otherServicesTabMenuLinks,

        residentialPurchaseSidebarLinks,
        residentialPurchaseTabMenuLinks,
        residentialRemortgageSidebarLinks,
        residentialRemortgageTabMenuLinks,
        residentialSaleSidebarLinks,
        residentialSaleTabMenuLinks,
        residentialTransferSidebarLinks,
        residentialTransferTabMenuLinks,
        residentialSalePurchaseSidebarLinks,
        residentialSalePurchaseTabMenuLinks,
        residentialEquitySidebarLinks,
        residentialEquityTabMenuLinks,
        residentialLegalAdviceSidebarLinks,
        residentialLegalAdviceTabMenuLinks, 
        residentialLeaseSidebarLinks,
        residentialLeaseTabMenuLinks,   
        residentialHtbSidebarLinks,
        residentialHtbTabMenuLinks, 
        residentialGeneralSidebarLinks,
        residentialGeneralTabMenuLinks,


        residentialSaleMatterTabMenuLinks,

        settingsServiceTabMenuLinks,
        settingsGlobalTabMenuLinks,

        usersEmployeeTabMenuLinks,
        usersSidebarLinks,
        branchesSidebarLinks,
        companiesSidebarLinks,

        settingsGeneralSidebarLinks,
        settingsConveyancingSidebarLinks,
        settingsServicesSidebarLinks,

        feesSubTabMenuLinks
    }
}