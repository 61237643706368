import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94987380"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col w-48 p-2" }
const _hoisted_2 = {
  key: 0,
  class: "submenu-header p-2 font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.header), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: link,
        to: {name: link.t, params:{service: link.p}, meta:{heading: link.n}},
        class: _normalizeClass(["block p-2 hover:bg-gray-100 transition-colors", link.c])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(link.n), 1)
        ]),
        _: 2
      }, 1032, ["to", "class"]))
    }), 128))
  ]))
}